import React from 'react';
import creditCardType from 'credit-card-type';

export enum CardType {
  MASTERCARD = 'mastercard',
  VISA = 'visa',
  AMERICAN_EXPRESS = 'amex',
  JCB = 'jcb',
  UPI = 'upi',
}

const supportedCardTypes = Object.values(CardType);

export const useCardType = (value: string) => {
  const finalValue = value.replace(/[^\d]/g, '');

  const results = React.useMemo(() => creditCardType(finalValue), [
    finalValue,
  ]);
  const details = results.length === 1 ? results[0] : undefined;
  const cardType = React.useMemo(() => {
    if (details) {
      const { type } = details;

      if (type === 'unionpay') {
        return CardType.UPI;
      }

      if (supportedCardTypes.includes(type as CardType)) {
        return type as CardType;
      }
    }
    return '';
  }, [
    details,
  ]);

  const maxLength = React.useMemo(() => {
    if (details && Array.isArray(details.lengths) && !!details.lengths.length) {
      return Math.max(...details.lengths);
    }
    return 20;
  }, [
    details,
  ]);

  const cvcMaxLength = React.useMemo(() => {
    if (details && typeof details?.code.size === 'number') {
      return details.code.size;
    }
    return 3;
  }, [
    details,
  ]);

  if (cardType === CardType.MASTERCARD) {
    return {
      isVisaCard: false,
      isMasterCard: true,
      isJcb: false,
      isUpi: false,
      isAmex: false,
      cardType: CardType.MASTERCARD,
      maxLength,
      cvcMaxLength,
    };
  }

  if (cardType === CardType.VISA) {
    return {
      isVisaCard: true,
      isMasterCard: false,
      isJcb: false,
      isUpi: false,
      isAmex: false,
      cardType: CardType.VISA,
      maxLength,
      cvcMaxLength,
    };
  }

  if (cardType === CardType.AMERICAN_EXPRESS) {
    return {
      isVisaCard: false,
      isMasterCard: false,
      isJcb: false,
      isUpi: false,
      isAmex: true,
      cardType: CardType.AMERICAN_EXPRESS,
      maxLength,
      cvcMaxLength,
    };
  }

  if (cardType === CardType.JCB) {
    return {
      isVisaCard: false,
      isMasterCard: false,
      isJcb: true,
      isUpi: false,
      isAmex: false,
      cardType: CardType.JCB,
      maxLength,
      cvcMaxLength,
    };
  }

  if (cardType === CardType.UPI) {
    return {
      isVisaCard: false,
      isMasterCard: false,
      isJcb: false,
      isUpi: true,
      isAmex: false,
      cardType: CardType.UPI,
      maxLength,
      cvcMaxLength,
    };
  }

  return {
    isVisaCard: false,
    isMasterCard: false,
    isJcb: false,
    isUpi: false,
    isAmex: false,
    cardType,
    maxLength,
    cvcMaxLength,
  };
};
